.home{
  //background-image: url(/wp-content/themes/gd-starter/assets/img/home.jpg);
  //background-size: auto 100%;
  //background-repeat: no-repeat;
  //background-size: cover;
  //background-position: top right;
  &:before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    z-index: -1;
    
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transform: scale(1.3);
    /* -webkit-filter: blur(12px);
    -moz-filter: blur(12px);
    -o-filter: blur(12px);
    -ms-filter: blur(12px);
    filter: blur(12px); */
    -webkit-animation: blur-scale 2.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards 0.75s; /* Safari 4+ */
    -moz-animation:    blur-scale 2.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards 0.75s; /* Fx 5+ */
    -o-animation:      blur-scale 2.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards 0.75s; /* Opera 12+ */
    animation:         blur-scale 2.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards 0.75s; /* IE 10+, Fx 29+ */
  }
  .header {
    height: 100vh;
    flex-direction: column;

    letter-spacing: 0.1em;
    padding: 32px 16px;
    @media screen and (min-width: 768px) {
      padding: 54px 42px 108px;
    }
    .menu{
      display: block;
    }
    .menu ul{
      display: block;
    }
    .menu li{
      margin: 0.5em 0;
      text-align: left;
      @media screen and (min-width: 1430px) {
        margin: 1em 0; 
      }
    }
    & > span{
      display: initial;
    }
  }
  .menu a{
    font-size: 16px;
    @media screen and (min-width: 1450px) {
      font-size: 18px;
    }
  }
  .header__menu-toggle{
    display: none;
  }
  &.right{
    .header__title{
      margin-left: auto;
    }
    .menu li{
      text-align: right;
    }
  }
}

@-webkit-keyframes blur-scale {
  0%   { 
    opacity: 0;
    transform: scale(1.3);
    -webkit-filter: blur(12px);
    -moz-filter: blur(12px);
    -o-filter: blur(12px);
    -ms-filter: blur(12px);
    filter: blur(12px);
  }
  100% { 
    transform: scale(1);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@-moz-keyframes blur-scale {
  0%   { 
    opacity: 0;
    transform: scale(1.3);
    -webkit-filter: blur(12px);
    -moz-filter: blur(12px);
    -o-filter: blur(12px);
    -ms-filter: blur(12px);
    filter: blur(12px);
  }
  100% { 
    transform: scale(1);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@-o-keyframes blur-scale {
  0%   { 
    opacity: 0;
    transform: scale(1.3);
    -webkit-filter: blur(12px);
    -moz-filter: blur(12px);
    -o-filter: blur(12px);
    -ms-filter: blur(12px);
    filter: blur(12px);
  }
  100% { 
    transform: scale(1);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes blur-scale {
  0%   { 
    opacity: 0;
    transform: scale(1.3);
    /* -webkit-filter: blur(12px);
    -moz-filter: blur(12px);
    -o-filter: blur(12px);
    -ms-filter: blur(12px); 
    filter: blur(12px);*/
  }
  100% { 
    transform: scale(1);
    /* -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0); */
    opacity: 1;
  }
}