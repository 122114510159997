.video{
  position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	//padding-top: 25px;
  height: 0;
  margin-bottom: 4rem;
  box-shadow: 0 9px 33px -9px rgba(0,0,0,0.5);
  &--widescreen-cinema{
    padding-bottom: 41.84%; /* 16:9 */
	  padding-top: 0;
  }
  &__iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}