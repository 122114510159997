*{
  box-sizing: border-box;
}

html {
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  background-color: #1F1F1F;
  font-family: "canada-type-gibson",sans-serif;	
  position: relative;
}

button, input{
  font-family: "canada-type-gibson",sans-serif;	
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: 400;
  text-transform: uppercase;
    letter-spacing: .2em;
}

p{
  font-family: 'CenturySchoolbook-Regular';
}

button{
  -webkit-appearance:  none;
   -moz-appearance:    none;
   appearance:         none;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

a, a:link, a:visited{
  text-decoration: none;
}

ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

img{
  width: 100%;
  display: block;
}