.page-template-default{
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto 350px;
  @media screen and (min-width: 768px) {
    background-size: auto 450px;
  }
  @media screen and (min-width: 1024px) {
    background-size: auto 550px;
  }
  @media screen and (min-width: 1280px) {
    background-size: auto 650px;
  }
  @media screen and (min-width: 1400px) {
    background-size: auto 750px;
  }
  &:before{
    @media screen and (min-width: 450px) {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 60%;
      background: linear-gradient(to right, rgba(31,31,31,1) 0%, rgba(31,31,31,1) 80%, rgba(31,31,31,0) 100%);
      height: 100%;
      z-index: -1;
    }
  }
  &:after{
    content: "";
    display: block;
    position: absolute;
    top: 150px;
    left: 0;
    width: 100%;
    background: linear-gradient(to top, rgba(31,31,31,1) 0%, rgba(31,31,31,0) 100%);
    height: 200px;
    z-index: -1;
    @media screen and (min-width: 768px) {
      top: 350px;
      height: 100px;
    }
    @media screen and (min-width: 1024px) {
      top: 250px;
      height: 300px;
    }
    @media screen and (min-width: 1280px) {
      top: 350px;
      height: 300px;
    }
    @media screen and (min-width: 1440px) {
      top: 350px;
      height: 400px;
    }
  }
}

.main{
  max-width: 1000px;
  margin: 0 auto 5rem;
  color: #ccc;
  @media screen and (min-width: 1280px) {
    max-width: 1180px;
  }
  @media screen and (min-width: 1440px) {
    //max-width: 1280px;
  }
  &__header{
    padding: 14rem 0 1.6rem;
    pointer-events: none!important;
    @media screen and (min-width: 450px) {
      padding: 14rem 0 3rem;
    }
    @media screen and (min-width: 768px) {
      padding: 16rem 0 3.6rem;
    }
    @media screen and (min-width: 1280px) {
      padding: 16rem 0 7.2rem;
    }
    @media screen and (min-width: 1440px) {
      padding: 17rem 0 7.6rem;
    }
    &.aos-animate{
      @media screen and (min-width: 768px) {
        opacity: .075 !important;
      }
    }
  }
  &__title{
    font-family: rollerscript-smooth, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #adaaa6;
    font-size: 60px;
    transform: rotate(-7deg);
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0;
    text-transform: lowercase;
    margin: 0 auto;
    width: 95%;
    @media screen and (min-width: 450px) {
      font-size: 112px;
      margin: 0 0 0 1rem;
    }
    @media screen and (min-width: 768px) {
      color: white;
      text-indent: -0.25em;
      font-size: 144px;
    }
  }
}

.default{
  font-size: 18px;
  line-height: 1.5;
  padding: 0 0.75rem;
  @media screen and (min-width: 768px) {
    padding: 0;
    font-size: 21px;
    line-height: 33px;
  }
  a{
    color: #FFF;
    transition: 0.2s;
    &:hover{
      color: #ffffff;
      text-decoration: underline;
    }
  }
}

article{
    font-size: 18px;
    line-height: 1.5;
    @media screen and (min-width: 768px) {
      font-size: 21px;
      line-height: 33px;
    }
    a{
      color: #b3a58f;
      transition: 0.3s;
      &:hover{
        color: white;
      }
    }
    blockquote{
      background: #1c1c1c;
      background: linear-gradient(to right, rgba(28,28,28,1) 0, rgba(28,28,28,1) 10px, rgba(28,28,28,0) 11px, rgba(28,28,28,0) 100%);
      margin: 0;
      border-radius: 1px;
      padding: 0.2rem 0 0.2rem 2rem;
      font-style: italic;
    }
}