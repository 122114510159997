.resume{
  background: #F5F5F5;
  box-shadow: 0 20px 100px -20px rgba(0,0,0,0.5);
  border-radius: 1px;
  padding-bottom: 2rem;
  color: #343434;
  max-width: calc(100% - 1rem);
  margin: 0 auto;
  @media screen and (min-width: 450px) {
    max-width: calc(100% - 2rem);
  }
  @media screen and (min-width: 600px) {
    max-width: calc(100% - 4rem);
  }
  @media screen and (min-width: 768px) {
    padding-top: 3.25rem;
    padding-bottom: 5rem;
  }
  @media screen and (min-width: 1280px) {
    max-width: 1050px;
    padding-top: 5rem;
  }
  &__main{
    margin: 0 auto;
    padding: 0 1rem 1rem;
    @media screen and (min-width: 450px) {
      padding: 0 2rem 2rem;
    }
    @media screen and (min-width: 600px) {
      padding: 2.5rem 3rem 3rem;
    }
    @media screen and (min-width: 768px) {
      max-width: 700px;
      padding: 0 3rem 3rem;
    }
    @media screen and (min-width: 1280px) {
      max-width: 900px;
    }
  }
  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    a{
      display: block;
      text-align: center;
      width: 100%;
      flex: 0 0 auto;
      background: #efefef;
      box-sizing: border-box;
      width: calc(100% + 2rem);
      margin-left: -1rem;
      padding: 0.5rem 0.5rem 0.4rem;
      order: 1;
      border-radius: 1px 1px 0 0;
      &:hover{
        color: #246fff;
        background: #EDEDED;
      }
      @media screen and (min-width: 450px) {
        width: calc(100% + 4rem);
        margin-left: -2rem;
      }
      @media screen and (min-width: 600px) {
        order: 2;
        background: transparent;
        width: 150px;
        margin-left: 0;
      }
      @media screen and (min-width: 768px) {
        margin-left: 0;
      }
      @media screen and (min-width: 1280px) {
        font-size: 1rem;
        width: 180px;
      }
    }
  }
  &__hgroup{
    width: 100%;
    flex: 0 0 auto;
    order: 2;
    margin-top: 1.5rem;
    @media screen and (min-width: 600px) {
      width: calc(100% - 150px);
      margin: 0;
    }
    @media screen and (min-width: 768px) {
      order: 1;
    }
    @media screen and (min-width: 1280px) {
      width: calc(100% - 180px);
    }
  }
  &__h2{
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.11em;
    line-height: 1;
    font-size: 20px;
    font-weight: 400;
    @media screen and (min-width: 768px) {
      font-size: 28px;
    }
  }
  &__h3{
    margin: 0.25rem 0 0;
    font-size: 20px;
    line-height: 1;
    font-family: 'CenturySchoolbook-Italic';
    font-weight: normal;
    font-style: italic;
    @media screen and (min-width: 768px) {
      margin: 0.5rem 0 0;
      font-size: 24px;
    }
  }
  &__download{
    color: #1F5FDA;
    font-family: 'CenturySchoolbook-Italic';
    font-size: 14px;
  }
  &__h4{
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.12em;
    position: relative;
    margin: 0;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    z-index: 10;
    &:after{
      content: "";
      display: block;
      position: absolute;
      top: 5px;
      left: 0;
      width: 100%;
      background: #BDBDBD;
      height: 1px;
    }
    &--noline{
      background: transparent;
      margin: 0;
      font-size: 11px;
      @media screen and (min-width: 768px) {
        font-size: 12px;
      }
      &:after{
        display: none;
      }
    }
    span{
      display: inline-block;
      position: relative;
      z-index: 9;
      &:after{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + 6px);
        background:#f4f5f4;
        height: 16px;
        z-index: -1;
      }
    }
  }
  &__h5{
    font-size: 18px;
    margin-bottom: 5px;
    line-height: 1.2;
    font-family: 'CenturySchoolbook-Italic';
    font-weight: normal;
    font-style: italic;
  }
  &__h6{
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.12em;
  }
  &__toplines{
    padding: 0.5rem;
    background: #EDEDED;
    border-radius: 1px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 1.5rem;
    @media screen and (min-width: 450px) {
      padding: 0.5rem 1rem;
    }
    @media screen and (min-width: 600px) {
      margin-top: 2rem;
    }
    @media screen and (min-width: 768px) {
      padding: 1rem 1.5rem;
      margin-top: 2rem;
    }
  }
  &__small{
    font-family: 'CenturySchoolbook-Regular';
    font-size: 18px;
    line-height: 1.2;
  }
  &__content{
    font-family: 'CenturySchoolbook-Regular';
    font-weight: normal;
    font-size: 19px;
    line-height: 26px;
    @media screen and (min-width: 768px) {
      font-size: 21px;
      line-height: 33px;
    }
    p{
      margin: 0;
    }
  }
  &__col{
    flex: 0 0 auto;
  }
  &__col--25{
    width: 50%;
    @media screen and (min-width: 768px) {
      width: 25%;
    }
  }
  &__col--30{
    width: 50%;
    @media screen and (min-width: 768px) {
      width: 30%;
    }
  }
  &__col--45{
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 45%;
      padding-right: 1rem;
    }
  }
  .col__45{
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 45%;
    }
  }
  .col--30{
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 30%;
    }
  }
  &__row{
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin: 0.5rem 0 1rem;
    @media screen and (min-width: 768px) {
      margin-bottom: 0.25rem;
    }
  }
  .col{
    flex: 0 0 auto;
    margin: 0.35em 0;
  } 
  .col--12{
    width: 50%;
    @media screen and (min-width: 600px) {
      width: 35%;
    }
    @media screen and (min-width: 768px) {
      width: 12.5%;
    }
  }
  .col--25{
    width: 50%;
    @media screen and (min-width: 600px) {
      width: 35%;
    }
    @media screen and (min-width: 768px) {
      width: 25%;
    }
  }
  .col--50{
    @media screen and (min-width: 450px) {
      width: 65%;
    }
    @media screen and (min-width: 600px) {
      width: 65%;
    }
    @media screen and (min-width: 768px) {
      width: 50%;
    }
  }

}