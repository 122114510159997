@font-face {
  font-family: 'CenturySchoolbook-Regular';
  src: url('./assets/fonts/CenturySchoolbook-Regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/CenturySchoolbook-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CenturySchoolbook-Italic';
  src: url('./assets/fonts/CenturySchoolbook-Italic-webfont.woff2') format('woff2'),
       url('./assets/fonts/CenturySchoolbook-Italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CenturySchoolbook-BoldItalic';
  src: url('./assets/fonts/CenturySchoolbook-BoldItalic-webfont.woff2') format('woff2'),
       url('./assets/fonts/CenturySchoolbook-BoldItalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CenturySchoolbook-Bold';
  src: url('./assets/fonts/CenturySchoolbook-Bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/CenturySchoolbook-Bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}