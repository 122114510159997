body div.wpcf7{
  margin: 3rem 0;
  input[type=text],
  input[type=email],
  input[type=number],
  textarea{
    width: 100%;
    display: block;
    background: rgba(255,255,255,0.025);
    border: 0;
    border-bottom: 2px solid rgba(255,255,255,0.2);
    color: white;
    font-size: 21px;
    padding: 2rem 0.25rem 0.5rem 1rem;
    transition: 0.2s;
    outline: none;
    position: relative;
    top: -1.25rem;
    left: -0.5rem;
    &:focus,
    &:hover{
      background: rgba(255,255,255,0.05);
    }
    &:focus{
      border-color: white;
    }
  }
  textarea{
    height: 100px;
  }
  p{
    margin: 0 0 0.5rem;
  }
  .wpcf7-not-valid-tip{
    position: relative;
    top: -1rem;
    display: block;
    margin-bottom: 1rem;
    color: #ff8a5d;
  }
  div.wpcf7-response-output{
    margin: 2rem 0;
    border-radius: 1px;
    padding: 1rem 0.5rem;
    color: black;
    text-align: center;
    font-size: 16px;
    border: 0;
    background: #ccc;
  }
  div.wpcf7-validation-errors, div.wpcf7-acceptance-missing{
    background: #ff8a5d;
    border: 0;
  }
  div.wpcf7-mail-sent-ok {
    background: #6fbda4;
  }
  label{
    letter-spacing: 0.25em;
    text-transform: uppercase;
    font-size: 12px;
    font-family: "canada-type-gibson",sans-serif;
    line-height: 1;
    //padding-left: 0.5rem;
    display: block;
    padding-left: 0.5rem;
  }
  input[type=submit]{
    letter-spacing: 0.25em;
    text-transform: uppercase;
    font-size: 15px;
    font-family: "canada-type-gibson",sans-serif;
    background: rgba(255,255,255,0.2);
    color: white;
    border: 0;
    border-radius: 1px;
    padding: 1rem;
    outline: none;
    margin-top: 1rem;
    min-width: 175px;
    transition: 0.2s;
    &:hover{
      background: #eee;
      color: black;
    }
  }
}