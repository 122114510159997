.header{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 24px 16px;
  @media screen and (min-width: 768px) {
    padding: 50px 32px;
  }
  @media screen and (min-width: 1024px) {
    padding: 50px 48px;
  }
  &__title{
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    width: calc(312px * 0.75);
    height: calc(42px * 0.75);
    @media screen and (min-width: 450px) {
      width: calc(312px * 0.85);
      height: calc(42px * 0.85);
    }
    @media screen and (min-width: 768px) {
      width: 312px;
      height: 42px;
    }
  }
  & > span{
    display: none;
  }
  & > div {
    @media screen and (max-width: 767px) {
      transform: none !important;
    }
  }
  .menu{
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
    }
    &--open{
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: block;
      background: rgba(47,47,47,0.95);
      z-index: 999;
      display: flex;
      align-content: center;
      justify-content: center;
      ul{
        align-content: center;
        justify-content: center;
      }
      li{
        flex: 0 0 auto;
        width: 100%;
      }
    }
  }
  .menu ul{
    display: flex;
    flex-wrap: wrap;
  }
  .menu li{
    margin: 0 2.2rem 0 0 ;
    text-align: right;
    @media screen and (min-width: 768px) {
      margin: 0 0 0 1.2rem;
    }
  }
  .menu a{
    color: white;
    font-size: 13px;
    letter-spacing: 0.25rem;
    text-transform: uppercase;
    transition: 0.2s;
    padding: 0.25rem 0;
    display: inline-block;
    &:hover{
      opacity: 0.5;
    }
  }
  &__menu-toggle{
    padding: 0;
    flex: 0 0 auto;
    background: transparent;
    border: 1px solid red;
    outline: none;
    border: 1px solid transparent;
    width: 28px;
    height: 28px;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    position: relative;
    z-index: 9999;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
  .menu__bar{
    width: calc(100% - 8px);
    height: 1px;
    background: white;
    transition: 0.1s;
    &:before,
    &:after{
      content: "";
      position: absolute;
      height: 1px;
      background: white;
      transition: 0.175s;
    }
    &:before{
      top: 4px;
      right: 0;
      width: 100%;
    }
    &:after{
      bottom: 4px;
      right: 0;
      width: calc(100% - 4px);
    }
  }
  &__menu-toggle--close{
    .menu__bar{
      background: rgba(0,0,0,0);
      &:before{
        top: 13px;
        right: 2px;
        width: 100%;
        transform: rotate(-45deg);
      }
      &:after{
        bottom: 12px;
        right: 2px;
        width: 100%;
        transform: rotate(45deg);
      }
    }
  }
}