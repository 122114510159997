/*
Theme Name: Thaddeus Armstrong
Version: 1.0
*/



@import 'includes/fonts';
@import 'includes/defaults';
@import 'includes/header';
@import 'includes/page';
@import 'includes/home';
@import 'includes/resume';
@import 'includes/video';
@import 'includes/gallery';
@import 'includes/wpcf7';

@import 'vendor/aos';
@import 'vendor/fontawesome/fontawesome';
@import 'vendor/fontawesome/light';
@import 'vendor/fontawesome/regular';
@import 'vendor/fontawesome/solid';