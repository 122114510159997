.gallery{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% - 1rem);
  margin: 0 auto;
  &__img{
    width: 100%;
    position: relative;
    margin-bottom: 0.5rem;
    box-shadow: 0 9px 33px -9px rgba(0,0,0,0.5);
    overflow: hidden;
    @media screen and (min-width: 600px) {
      margin-bottom: 1rem;
    }
    &--50{
      @media screen and (min-width: 450px) {
        width: calc(50% - 0.25rem);
      }
      @media screen and (min-width: 600px) {
        width: calc(50% - 0.5rem);
      }
    }
    &:hover{
      .gallery__actions{
        transform: translate(-50%, -50%);
        opacity: 1;
      }
      img{
        transform: scale(1.1);
        opacity: 0.4;
      }
    }
  }
  &__actions{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -300%);
    width: 100%;
    text-align: center;
    transition: 0.5s;
    z-index: 9;
    opacity: 0;
    button, a{
      background: transparent;
      border: 0;
    }
  }
  &__link{
    font-size: 1.8rem;
    display: inline-block;
    margin: 0;
    color: rgba(255,255,255,0.5);
    margin: 0.5rem;
    padding: 0.5rem;
    transition: 0.3s; 
    outline: none;
    position: relative;
    &:hover{
      color: rgba(255,255,255,1);
    }
  }
  i{
    transition: 0.3s; 
  }
  img{
    transition: 0.6s;
  }
  .toast{
    position: absolute;
    top: -40px;
    line-height: 1;
    font-size: 14px;
    display: inline-block;
    width: 140px;
    left: 50%;
    margin-left: -70px;
    padding: 0.5rem;
    border-radius: 1px;
    color: white;
    background: black;
    opacity: 0;
    &.active{
      -webkit-animation: clipboard 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards 0.1s; /* Safari 4+ */
      -moz-animation:    clipboard 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards 0.1s; /* Fx 5+ */
      -o-animation:      clipboard 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards 0.1s; /* Opera 12+ */
      animation:         clipboard 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards 0.1s; /* IE 10+, Fx 29+ */
    }
  }
}



@-webkit-keyframes clipboard {
  0%   { 
    opacity: 0;
    transform: scale(1.3);
    -webkit-filter: blur(12px);
    -moz-filter: blur(12px);
    -o-filter: blur(12px);
    -ms-filter: blur(12px);
    filter: blur(12px);
  }
  100% { 
    transform: scale(1);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@-moz-keyframes clipboard {
  0%   { 
    opacity: 0;
    transform: scale(1.3);
    -webkit-filter: blur(12px);
    -moz-filter: blur(12px);
    -o-filter: blur(12px);
    -ms-filter: blur(12px);
    filter: blur(12px);
  }
  100% { 
    transform: scale(1);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@-o-keyframes clipboard {
  0%   { 
    opacity: 0;
    transform: scale(1.3);
    -webkit-filter: blur(12px);
    -moz-filter: blur(12px);
    -o-filter: blur(12px);
    -ms-filter: blur(12px);
    filter: blur(12px);
  }
  100% { 
    transform: scale(1);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes clipboard {
  0%   { 
    opacity: 0;
    top: -40px;
  }
  20% { 
    opacity: 1;
    top: -40px;
  }
  100%   { 
    opacity: 0;
    top: -80px;
  }
}